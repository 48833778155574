<template>
  <div>
    <Form />
  </div>
</template>
<script>
const Form = () => import("../form/Form.vue");

export default {
  components: { Form }
};
</script>
